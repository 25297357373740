<template>
  <div class="middle" v-if="link">
    <a :href="link" class="flix-html-a">{{ $t('message.load', {name: $tc('message.targetPage', 1)}) }}</a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      link: false
    }
  },
  methods: {
    getLink () {
      this.$flix_post({
        url: 'page/goforward',
        data: {
          go: this.$route.params.id
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.link = ret.data[1]
            setTimeout(function () {
              window.open(this.link, '_self')
            }.bind(this), 2000)
          } else {
            this.$router.push({ name: '/' })
          }
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getLink()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .middle
    display: flex
    align-items: center
    height: 100vh
    width: 100%
    text-align: center
    a
      flex: 1
      display: block

</style>
